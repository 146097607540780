import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Image from '../components/image'

const Contact = () => {
  return (
    <Layout>
      <SEO
        title='Nuestros Clientes'
        description='Buscas asesoría personaliza, escríbenos y te ayudaremos a crear la próxima aplicación del mercado.'
      />
      {/* Begin Title */}
      <section className='bg-gray-900'>
        <div className='container bg-grey-lightest mx-auto shadow rounded pb-4 bg-cover'>
          <div className='mt-2 p-4 border-b border-grey-light  text-center'>
            <h2 className='xs:text-base sm:text-3xl text-4xl font-thin text-white'>
              Nuestros Clientes
            </h2>
          </div>
        </div>
      </section>
      {/* Begin Form */}
      <section>
        <div className='container mx-auto mb-4 mt-12'>
          <div className='w-full max-w-xs is-center'>
            <h2 className='mb-12 text-center'>
              Para tener una atención personalizada
            </h2>
            <p className='mt-2 mb-2 text-justify'>
              Recuerda detallar los requerimientos de tu sistema para poder
              tener una mejor experiencia al crear tu próximo producto
            </p>
            <div class='mt-12 mb-12'>
              <Image
                name='contact'
                alt='Contactanos'
                width=''
                height=''
                className='h-56 w-full object-cover object-center'
              />
            </div>
            <div className='flex flex-wrap -mx-3 mb-6 mt-12'>
              <div className='w-full px-3 mb-6 md:mb-0 text-center'>
                <button className='bg-orange-500 hover:bg-orange-400 text-white font-bold py-2 px-4 border-b-4 border-orange-700 hover:border-orange-500 rounded'>
                  <a href='mailto:info@cintanegra.net' target='_blank'>
                    Enviar
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contact
